import { useNavigate } from "react-router-dom"
import styles from "./footer.module.css"
import NavLinkScroll from "../nav-link-scroll/nav-link-scroll";

const Footer = ({ navigateTrigger }) => {
    const navigate = useNavigate()

    const goToAnotherPageAndScroll = async (page, block) => {
        navigateTrigger(block)
        navigate(page, { state: { blockName: block } })
    };

    return (
        <div className={styles.footer_block}>
            <div className="container">
                <div className={styles.content}>
                    <div className={styles.info_blocks}>
                        <div className={styles.logo_block}>
                            <img src="/img/logo.svg" alt="logo-icon" />
                        </div>

                        <div className={styles.links_block}>
                            <div className={styles.links}>
                                <div className={styles.link_button}>
                                    <NavLinkScroll to="/industries">Industries</NavLinkScroll>
                                </div>

                                <div className={styles.link_button}>
                                    <NavLinkScroll to="/services">Services</NavLinkScroll>
                                </div>

                                <div className={styles.link_button}>
                                    <NavLinkScroll to="/technologies">Technologies</NavLinkScroll>
                                </div>
                            </div>

                            <div className={`${styles.socials} ${styles.desktop}`}>
                                <a href="/" className={styles.social_link}><img src="/img/facebook-icon.svg" alt="facebook-icon" /></a>
                                <a href="/" className={styles.social_link}><img src="/img/twitter-icon.svg" alt="twitter-icon" /></a>
                                <a href="/" className={styles.social_link}><img src="/img/direct-icon.svg" alt="direct-icon" /></a>
                            </div>
                        </div>

                        <div className={styles.cases_block}>
                            <h3 className={styles.block_title}>Case Studies</h3>
                            <div className={styles.cases}>
                                <div className={styles.case}>
                                    <button onClick={() => goToAnotherPageAndScroll("/web-apps", "chemwatch")} className={styles.case_name}>Chemwatch</button>
                                    <p className={styles.case_info}>The World's leader in Chemical Safety Management.</p>
                                </div>

								<div className={styles.case}>
                                    <button onClick={() => goToAnotherPageAndScroll("/web-apps", "eco-credit")} className={styles.case_name}>EcoCredit</button>
                                    <p className={styles.case_info}>CO2 credit marketplace.</p>
                                </div>
                            </div>

                            <div className={styles.cases}>
                                <div className={styles.case}>
                                    <button onClick={() => goToAnotherPageAndScroll("/mobile-apps", "i-eat-better")} className={styles.case_name}>I Eat Better</button>
                                    <p className={styles.case_info}>Food diary mobile application..</p>
                                </div>

								<div className={styles.case}>
                                    <button onClick={() => goToAnotherPageAndScroll("/web-apps", "trakopolis")} className={styles.case_name}>Trakopolis</button>
                                    <p className={styles.case_info}>Real-time tracking and management of corporate assets..</p>
                                </div>
                            </div>

                            <div className={styles.cases}>
                                <div className={styles.case}>
                                    <button onClick={() => goToAnotherPageAndScroll("/mobile-apps", "feaster")} className={styles.case_name}>Feaster</button>
                                    <p className={styles.case_info}>Mobile client for events organizing platform.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.socials} ${styles.mobile}`}>
                        <a href="/" className={styles.social_link}><img src="/img/facebook-icon.svg" alt="facebook-icon" /></a>
                        <a href="/" className={styles.social_link}><img src="/img/twitter-icon.svg" alt="twitter-icon" /></a>
                        <a href="/" className={styles.social_link}><img src="/img/direct-icon.svg" alt="direct-icon" /></a>
                    </div>

                    <p className={styles.rights}>©Techcore 2012-2024. All rights reserved</p>
                </div>
            </div>
        </div>
    )
}

export default Footer