import { useLocation } from "react-router-dom"
import CaseInfoBlock from "../shared/case-info-block/case-info-block"
import CaseMainBlock from "../shared/case-main-block/case-main-block"
import ContactUs from "../shared/contact-us/contact-us"
import MainBlock from "../shared/main-block/main-block"
import styles from "./mobile-apps.module.css"
import { useEffect } from "react"

const MobileApps = () => {
    const handleClickScroll = () => {
        const element = document.getElementById("chemwatch-mobile")

        if (element) {
            element.scrollIntoView({ behavior: "smooth" })
        }
    }

    const { state } = useLocation()
    const { blockName } = state || {}

    useEffect(() => {
        const el = document.getElementById(blockName)

        if (el) {
            el.scrollIntoView(true)
        }
    }, [blockName])

    return (
        <div className={styles.mobile_apps_block}>
            <div className={styles.main_block}>
                <MainBlock
                    title="Crossplatform Mobile Development"
                    description="Case Studies"
                    imgPath="/img/main-illustration-mobile-apps.webp"
                    backgroundImgPath="/img/main-background-dots-figure.svg"
                    onMoreButtonClick={handleClickScroll}
                    className="mobile-web-apps"
                />
            </div>

            <div id="chemwatch-mobile"></div>
            <CaseMainBlock
                withTopMenu={false}
                backgroundColor="linear-gradient(116.98deg, #293771 0%, #1D2245 80.68%)"
                backgroundImagePath="/img/main-background-chemwatch.svg"
                imgPath="/img/case-illustration-chemwatch-mobile.webp"
                infoText="The World's leader in Chemical Safety Management"
                caseName="Chemwatch"
                cases={[
                    { amount: 11, text1: "years", text2: "of cooperation" },
                    { amount: 23, text2: "engineering team" },
                    { amount: 6, text1: "clients", text2: "of the top 20 Fortune 500" }
                ]}
                solutions={["Web application", "QA", "Security audit", "Product Design", "Oracle", "ASP.NET Core", "ASP.NET Web API"]}
                isMobile={true}
            />

            <CaseInfoBlock
                titleImgPath="/img/info-illustration-chemwatch-mobile.webp"
                isImgOutContainer={true}
                itemKey="chemwatch-mobile"
                infoList={[
                    {
                        title: "About",
                        info: (
                            <span>
                                Chemwatch is a leading provider of comprehensive chemical management software, offering access to the world’s largest database of chemicals. Their products are trusted by over 7,000 chemical manufacturers, users and suppliers worldwide, including 6 of the top 20 Fortune 500 companies.
                                <br /><br />
                                With the Smarter Suite mobile app, customers can access extensive health and safety information, including risk assessment, and manage their chemical inventory from one location.
                            </span>
                        )
                    },
                    {
                        title: "Goal & Deliverables",
                        info: (
                            <span>
                                The goal of the Smarter Suite project was to develop a cross-platform mobile application that would replicate the functionality of their web portal with full support for both mobile devices and tablets. Our team successfully released the initial version of the Smarter Suite mobile app. Since then, we have continued to work on the project, adding new features that correspond to the full range of functionality available on the web portal.
                            </span>
                        )
                    }
                ]}
            />

            <div id="pagos"></div>
            <CaseMainBlock
                withTopMenu={false}
                backgroundColor="linear-gradient(122.07deg, #007AFE -0.26%, #0717A0 105.25%)"
                backgroundImagePath="/img/main-background-pagos.svg"
                imgPath="/img/case-illustration-pagos-mobile.webp"
                infoText="Intelligent Payment Infrastructure for Commerce"
                caseName="Pagos"
                cases={[
                    { amount: "1B+", text2: "transactions tracked" },
                    { amount: 25, text2: "engineering team" },
                    { amount: "15+", text2: "PSPs integrated" }
                ]}
                solutions={["Web application", "QA", "Security audit", "Product Design", "AWS", "Amazon Redshift", "Microservices", "Node.js", "React.js", "eCharts.js"]}
                isMobile={true}
            />

            <CaseInfoBlock
                titleImgPath="/img/info-illustration-pagos-mobile.webp"
                isImgOutContainer={true}
                itemKey="pagos-mobile"
                infoList={[
                    {
                        title: "About",
                        info: (
                            <span>
                                Pagos is a startup in the field of payment systems infrastructure. The company is building a data platform and API-based microservices that can be integrated with any payment stack.
                            </span>
                        )
                    },
                    {
                        title: "Goal & Deliverables",
                        info: (
                            <span>
                                Our goal for this project was to create a mobile application that could replicate the functionality of Pagos' web application, with a particular focus on providing users with payment data visualizations through charts and enabling them to receive notifications related to payment trends or issues.
                                <br /><br />
                                The final deliverables of this project included a fully functional cross-platform mobile application built using Flutter, which replicated the web application’s payment data visualization and notification features. Additionally, the application was designed to be intuitive and user-friendly, with a clean and modern interface that aligned with Pagos' branding. Overall, our collaboration with Pagos product team resulted in a successful project that met all of the company’s goals and delivered a high-quality mobile application to their customers.
                            </span>
                        )
                    }
                ]}
            />

            <div id="fast-and-yammy"></div>
            <CaseMainBlock
                withTopMenu={false}
                backgroundColor="linear-gradient(118.8deg, #0098D9 -15.13%, #06AD74 98.55%), linear-gradient(120.42deg, #37C1FD -36.6%, #33D09A 73.72%)"
                backgroundImagePath="/img/main-background-fast-and-yammy.svg"
                imgPath="/img/case-illustration-fast-and-yammy-mobile.webp"
                infoText="Food delivery mobile app"
                caseName="Fast And Yammy"
                cases={[
                    { amount: 4, text1: "months", text2: "to MVP" },
                    { amount: "92%", text2: "code sharing iOS/Android" }
                ]}
                solutions={["Mobile app", "Flutter"]}
                isMobile={true}
            />

            <CaseInfoBlock
                titleImgPath="/img/info-illustration-fast-and-yammy-mobile.webp"
                isImgOutContainer={true}
                itemKey="fast-and-yammy-mobile"
                infoList={[
                    {
                        title: "About",
                        info: (
                            <span>
                                Fast & Yammy is a new food delivery platform startup that offers a wide selection of restaurants for users to order from, with a focus on delivering high-quality and unique dining experiences.
                            </span>
                        )
                    },
                    {
                        title: "Goal & Deliverables",
                        info: (
                            <span>
                                Our primary goal for this project was to create a user-friendly food delivery app that was intuitive and easy to use, with a modern interface that aligned with current design trends. To achieve this, we conducted extensive user research and usability testing to ensure that the app met the needs of our target audience.
                                <br /><br />
                                Our deliverables included a fully functional food delivery mobile application built using Flutter framework that offered a range of features, such as the ability to browse restaurants and menus, select dishes, and track orders in real-time. Additionally, the app was integrated with third-party payment systems, making it easy for users to pay for their orders securely.
                            </span>
                        )
                    }
                ]}
                additionalPaddingRight={50}
            />

            <div id="i-eat-better"></div>
            <CaseMainBlock
                withTopMenu={false}
                backgroundColor="linear-gradient(122.07deg, #007AFE -0.26%, #0717A0 105.25%)"
                backgroundImagePath="/img/main-background-i-eat-better.svg"
                imgPath="/img/case-illustration-i-eat-better-mobile.webp"
                infoText="FOOD DIARY MOBILE APPLICATION"
                caseName="I Eat Better"
                cases={[
                    { amount: "5M+", text2: "installs" },
                    { amount: 5, text1: "months", text2: "to market" },
                    { amount: "92%", text2: "code sharing iOS/Android" }
                ]}
                solutions={["Mobile app", "UX/UI Design", "Xamarin", "Xamarin.Forms"]}
                isMobile={true}
            />

            <CaseInfoBlock
                titleImgPath="/img/info-illustration-i-eat-better-mobile.webp"
                isImgOutContainer={true}
                itemKey="i-eat-better-mobile"
                infoList={[
                    {
                        title: "About",
                        info: (
                            <span>
                                IEatBetter is a mobile app that enables users to monitor their daily food intake and make healthier eating choices. The app comes with a built-in database containing thousands of food items, making it easy for users to add their customized meals. The primary objective of the app is to streamline diet tracking, while promoting healthier eating habits.
                            </span>
                        )
                    },
                    {
                        title: "Goal & Deliverables",
                        info: (
                            <span>
                                The primary goal was to rewrite the existing client app, which was not extendable due to spaghetti code and had performance issues in the app’s database. By using Xamarin.Forms, we aimed to create a shared codebase for both iOS and Android platforms, reduce development costs, and simplify maintenance and support.
                                <br /><br />
                                As a result, our team released a fully functional mobile app with a user-friendly interface and an efficient design, with an impressive percentage of codebase sharing — 92%. The app’s enhanced performance makes it more responsive and reliable, improving the user experience.
                            </span>
                        )
                    }
                ]}
            />

            <div id="feaster"></div>
            <CaseMainBlock
                withTopMenu={false}
                backgroundColor="linear-gradient(116.73deg, #9F2FFF 0%, #5700A2 73.63%)"
                backgroundImagePath="/img/main-background-feaster.svg"
                imgPath="/img/case-illustration-feaster-mobile.webp"
                infoText="MOBILE CLIENT FOR EVENTS ORGANIZING PLATFORM"
                caseName="Feaster"
                cases={[
                    { amount: 3, text1: "months", text2: "to MVP" },
                    { amount: "94%", text2: "code sharing iOS/Android" }
                ]}
                solutions={["Mobile app", "Xamarin.Forms"]}
                isMobile={true}
            />

            <CaseInfoBlock
                titleImgPath="/img/info-illustration-feaster-mobile.webp"
                isImgOutContainer={true}
                itemKey="feaster-mobile"
                infoList={[
                    {
                        title: "About",
                        info: (
                            <span>
                                Feaster is a startup that simplifies event planning for users. The platform offers a range of features, including budget management, venue selection, and the ability to hire professional services such as hosts, DJs, singers, and animators. With Feaster, users can effortlessly invite guests through social networks or their contact lists. As a startup, Feaster aims to revolutionize the event planning industry by offering an easy-to-use, comprehensive platform for users to plan their events.
                            </span>
                        )
                    },
                    {
                        title: "Goal & Deliverables",
                        info: (
                            <span>
                                Our primary goal for the Feaster project was to develop a cross-platform mobile app within a tight timeframe due to the startup’s need to bring the product to market as soon as possible.
                                <br /><br />
                                Despite the tight deadlines, our team successfully released a fully functional mobile app for both iOS and Android platforms within five months of the project’s inception, achieving an impressive codebase sharing percentage of 94%.
                            </span>
                        )
                    }
                ]}
            />

            <ContactUs />
        </div>
    )
}

export default MobileApps